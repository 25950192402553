import React from 'react'
import { Layout } from 'antd'

const { Footer } = Layout

export const LayoutFooter: React.FC = () => {
  const year = new Date().getFullYear()

  return (
    <Footer style={{ textAlign: 'center', backgroundColor: '#f8f8f2' }}>
      © {year} Paris Society
    </Footer>
  )
}
