import { Segmented } from 'antd'
import { getConfig, setLanguage } from '../../i18n/Metronici18n'

const LangSelector = () => {
  return (
    <Segmented
      value={getConfig().selectedLang}
      onChange={(value) => setLanguage(String(value))}
      options={[
        {
          label: 'FR',
          value: 'fr',
        },
        {
          label: 'EN',
          value: 'en',
        },
      ]}
    />
  )
}

export default LangSelector
